import { Link } from "gatsby";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { StoreContext } from "../context/store-context";
import Logo from "../images/assets/adisoft-logo-dark.svg";
const isBrowser = typeof window !== "undefined"

const Header = () => {
  const { checkout } = useContext(StoreContext);
  const [lang, setLang] = useState(null);
  useEffect(() => {
    if (isBrowser) {
      setLang(window.localStorage.getItem("langSelect"))
    }
  }, [])

  return (
    <div className="nav-wrapper">
      <nav className="bg-white border-gray-200 ">
        <div className="flex flex-wrap justify-center sm:justify-between items-center mx-auto max-w-screen-xl px-4 md:px-6 py-2.5">
          <Link to="/" className="flex items-center">
            <Logo width={200} className="mr-3 h-6 mb-4 sm:mb-0 sm:h-9" />
          </Link>
          <div className="flex items-center relative">
            <a
              href="tel:+37069930080"
              className="mr-6 text-sm font-medium text-gray-500  hover:underline"
            >
              +370 (699) 300 80
            </a>
            <Link
              to="/account"
              className="text-sm font-medium text-blue-600  hover:underline pr-4"
            >
              Account
            </Link>
            <Link to="/search" className="pr-4">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </Link>
            {
              lang === "EN" || lang === null ? <a onClick={() => {localStorage.setItem('langSelect', "LT"); window.location.reload()}} href="#"><svg className="w-5 h-5 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5 3"><path fill="#BE3A34" d="M0 0h5v3H0z"/><path fill="#046A38" d="M0 0h5v2H0z"/><path fill="#FFB81C" d="M0 0h5v1H0z"/></svg></a> : <a onClick={() => {localStorage.setItem('langSelect', "EN"); window.location.reload()}} href="#"><svg className="w-5 h-5 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30"><clipPath id="a"><path d="M0 0v30h60V0z"/></clipPath><clipPath id="b"><path d="M30 15h30v15zv15H0zH0V0zV0h30z"/></clipPath><g clipPath="url(#a)"><path d="M0 0v30h60V0z" fill="#012169"/><path d="M0 0l60 30m0-30L0 30" stroke="#fff" strokeWidth="6"/><path d="M0 0l60 30m0-30L0 30" clipPath="url(#b)" stroke="#C8102E" strokeWidth="4"/><path d="M30 0v30M0 15h60" stroke="#fff" strokeWidth="10"/><path d="M30 0v30M0 15h60" stroke="#C8102E" strokeWidth="6"/></g></svg></a>
             
            }

  
            <Link to="/cart">
              <div className="flex flex-row cursor-pointer truncate rounded cart">
                <div className="flex flex-row-reverse w-full">
                  <div>
                    <div className="absolute text-xs rounded-full px-1 font-bold -top-[8px] -right-2 bg-red-700 text-white">
                      {checkout?.lineItems?.edges?.length || 0}
                      
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100%"
                      height="100%"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="w-5 h-5"
                    >
                      <circle cx="9" cy="21" r="1"></circle>
                      <circle cx="20" cy="21" r="1"></circle>
                      <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                    </svg>
                  </div>
                </div>
              </div>
              
            </Link>
             
            </div>
   
        </div>
      </nav>
      <nav className="bg-gray-50 ">
        <div className="py-3 px-4 mx-auto max-w-screen-xl md:px-6">
          <div className="flex items-center">
            <ul className="flex overflow-x-auto flex-row mt-0 mr-6 text-sm font-medium">
              <li className="mb-1">
                <Link
                  to="/"
                  className="text-gray-900 sm:mr-8 mr-5 hover:underline"
                  aria-current="page"
                >
                  Home
                </Link>
              </li>
              <li className="mb-1 min-w-[80px] sm:min-w-[auto]">
                <Link
                  to="/aboutus"
                  className="text-gray-900 sm:mr-8 mr-5  hover:underline"
                >
                  About us
                </Link>
              </li>
              <li className="mb-1 sm:min-w-[auto]">
                <Link
                  to="/catalog"
                  className="text-gray-900 sm:mr-8 mr-5  hover:underline"
                >
                  Shop
                </Link>
              </li>
              <li className="mb-1 sm:min-w-[auto]">
                <Link
                  to="/blog"
                  className="text-gray-900 sm:mr-8 mr-5 hover:underline"
                >
                  Blog
                </Link>
              </li>
              <li className="mb-1 sm:min-w-[auto]">
                <Link to="/contacts" className="text-gray-900 hover:underline">
                  Contacts
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
